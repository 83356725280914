import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import RouteList from './routes/Routes';
import { UserContextProvider } from "./provider/UserContext";
import { CartContextProvider } from "./provider/CartContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <UserContextProvider>
      <CartContextProvider>
        <BrowserRouter>
          <RouteList />
        </BrowserRouter>
      </CartContextProvider>
    </UserContextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
