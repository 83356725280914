import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";

import HttpHelper from "../../../helper/HttpHelper";
import EventService from "../../../services/EventService";

function EventDetails() {

    const url = HttpHelper.STRAPI_URL;

    let { id } = useParams();

    const [loding, setLoding] = useState(true);
    let [eventDetails, setEventDetails] = useState([]);

    async function getOneEvent() {
        if (id) {
            let query = {
                populate: "*"
            };
            const response = await EventService.getOne(id, query);
            if (response.status === 200) {
                console.log(response.data.data);
                setEventDetails(response.data.data);
                setLoding(false);
            }
        };
    }

    useEffect(() => {
        try {
            getOneEvent();
        } catch (error) {
            console.log(error);
        }
    }, []);


    return (
        <>
            <Header />
            <section className="event-details-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                            <div className="event-dtls-left-img">
                                {!loding &&
                                    <div className="event-img">
                                        <img className="img-fluid" src={url + eventDetails.attributes.media?.data.attributes.url} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-8 mb-4">
                            <div className="event-dtls-content">
                                {!loding &&
                                    <>
                                        <h3>{eventDetails.attributes.title}</h3>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: eventDetails.attributes.description }}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default EventDetails;