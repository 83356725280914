import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";

import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { Toast } from '../../Layout/Alert';

import AuthService from "../../../services/AuthService";
import { RegisterForm } from "../../../helper/ValidationHelper";

function Register() {

    const [isLoading, setIsLoading] = useState(false);

    const submitHandler = async (formvalues) => {
        try {
            setIsLoading(true);
            let response = await AuthService.Register(formvalues);
            if (response.status === 200) {
                window.location.replace("/login");
            } else {
                setIsLoading(false);
                Toast.fire({
                    icon: 'warning',
                    title: "Something went wrong. try again later"
                });
            }
        } catch (err) {
            setIsLoading(false);
            let message = err.message;
            if (err.code === "ERR_BAD_REQUEST") {
                if (err.response.data.error) {
                    message = err.response.data.error.message;
                }
            }
            Toast.fire({ icon: 'error', title: message });
        }
    };

    const initialValues = {
        username: "",
        email: "",
        mobile_number: "",
        password: "",
        cpassword: "",
        pin_code: "",
        city: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: RegisterForm,
        onSubmit: (values, action) => {
            submitHandler(values);
            // action.resetForm()
        },
    });

    return (
        <>
            <Header />
            <section className="register-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 offset-md-3">
                            <div className="sign-form-wrap">
                                <div className="login-title mb-4">
                                    <div className="log-logo">
                                        <img className="img-fluid" src="./images/logo.png" />
                                    </div>
                                    <h3>Sign Up</h3>
                                </div>
                                <div className="signin-form">
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="row">
                                            <div className="col-12 col-md-12 mb-3">
                                                <div className="form-group mb-3">
                                                    <label>Name *</label>
                                                    <input
                                                        type="text"
                                                        placeholder=""
                                                        name="username"
                                                        autoComplete="off"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.username}
                                                        className="form-control"
                                                    />
                                                    {formik.touched.username && formik.errors.username ? (
                                                        <p className="text-danger small "><strong>{formik.errors.username}</strong></p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 mb-3">
                                                <div className="form-group mb-3">
                                                    <label>Email *</label>
                                                    <input
                                                        type="email"
                                                        placeholder=""
                                                        name="email"
                                                        autoComplete="off"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.email}
                                                        className="form-control"
                                                    />
                                                    {formik.touched.email && formik.errors.email ? (
                                                        <p className="text-danger small "><strong>{formik.errors.email}</strong></p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 mb-3">
                                                <div className="form-group mb-3">
                                                    <label>Phone Number *</label>
                                                    <input
                                                        type="text"
                                                        placeholder=""
                                                        name="mobile_number"
                                                        autoComplete="off"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.mobile_number}
                                                        className="form-control"
                                                    />
                                                    {formik.touched.mobile_number && formik.errors.mobile_number ? (
                                                        <p className="text-danger small "><strong>{formik.errors.mobile_number}</strong></p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 mb-3">
                                                <div className="form-group mb-3">
                                                    <label>Password *</label>
                                                    <input
                                                        type="password"
                                                        placeholder=""
                                                        name="password"
                                                        autoComplete="off"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.password}
                                                        className="form-control"
                                                    />
                                                    {formik.touched.password && formik.errors.password ? (
                                                        <p className="text-danger small "><strong>{formik.errors.password}</strong></p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 mb-3">
                                                <div className="form-group mb-3">
                                                    <label>Confirm Password *</label>
                                                    <input
                                                        type="text"
                                                        placeholder=""
                                                        name="cpassword"
                                                        autoComplete="off"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.cpassword}
                                                        className="form-control"
                                                    />
                                                    {formik.touched.cpassword && formik.errors.cpassword ? (
                                                        <p className="text-danger small "><strong>{formik.errors.cpassword}</strong></p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 mb-3">
                                                <div className="form-group mb-3">
                                                    <label>Pin/Zip code *</label>
                                                    <input
                                                        type="text"
                                                        placeholder=""
                                                        name="pin_code"
                                                        autoComplete="off"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.pin_code}
                                                        className="form-control"
                                                    />
                                                    {formik.touched.pin_code && formik.errors.pin_code ? (
                                                        <p className="text-danger small "><strong>{formik.errors.pin_code}</strong></p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 mb-3">
                                                <div className="form-group mb-3">
                                                    <label>City *</label>
                                                    <input
                                                        type="text"
                                                        placeholder=""
                                                        name="city"
                                                        autoComplete="off"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.city}
                                                        className="form-control"
                                                    />
                                                    {formik.touched.city && formik.errors.city ? (
                                                        <p className="text-danger small "><strong>{formik.errors.city}</strong></p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 mb-3">
                                                <div className="form-group form-check">
                                                    <input type="checkbox" className="form-check-input" id="agree_privacy_policy" />
                                                    <label className="form-check-label" htmlFor="agree_privacy_policy">I agree to the Terms and conditions and Privacy Policy</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12">
                                                <div className="form-group submit-btn-wrap">
                                                    <ul>
                                                        <li>
                                                            {/* <input onClick={handleSubmit} className="btn btn-warning" type="button" name="" value="Sign Up" /> */}
                                                            <button disabled={isLoading} type='submit' className="btn btn-warning">
                                                                {isLoading ?
                                                                    <div className="spinner-border" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div> : "Sign Up"}
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <p> Already have an account? <Link to="/login">Sign In </Link></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Register;