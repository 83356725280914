import { createContext, useState, useEffect } from "react";

import StorageHelper from '../helper/StorageHelper';

export const CartContext = createContext(null);

export const CartContextProvider = (props) => {

    const [cartItems, setCartItems] = useState(StorageHelper.getCart() || []);

    const getTotalCartAmount = () => {
        let totalAmount = 0;
        for (const item in cartItems) {
            if (cartItems[item]) {
                let skuses = cartItems[item];
                totalAmount += skuses.attributes.actual_price * cartItems[item].count;
            }
        }
        return totalAmount;
    };

    const getTotalCartCount = () => {
        let count = 0;
        for (const item in cartItems) {
            if (cartItems[item])
                count += cartItems[item].count;
        }
        return count;
    };

    const addToCart = (item) => {
        console.log(item);
        console.log(cartItems);
        // setCartItems((prev) => ({ ...prev, [item.id]: item }));
        let arr = [];
        let ext = cartItems.length;
        if (cartItems.length) {
            for (const i in cartItems) {
                arr[i] = cartItems[i];
                if (cartItems[i].id == item.id) {
                    ext = i;
                }
            }
        }
        arr[ext] = item;
        setCartItems(arr);
    };

    const updateToCart = (item) => {
        console.log(item);
        console.log(cartItems);
        let arr = [];
        if (cartItems.length) {
            for (const i in cartItems) {
                arr[i] = cartItems[i];
                if (cartItems[i].id === item.id) {
                    arr[i] = item;
                }
            }
        }
        setCartItems(arr);
    };

    const removeFromCart = (itemId) => {
        console.log(itemId);
        console.log(cartItems);
        // setCartItems((prev) => ({ ...prev, [itemId]: '' }));
        // setCartItems(cartItems.filter(item => item.id !== itemId));
        if (cartItems.length) {
            let arr = [];
            for (const i in cartItems) {
                if (cartItems[i].id != itemId) {
                    arr[i] = cartItems[i];
                }
            }
            setCartItems(arr);
        } else {
            setCartItems([]);
        }
    };

    const getOneItemCount = (itemId) => {
        let arr = 0;
        for (const i in cartItems) {
            if (cartItems[i].id == itemId) {
                arr = cartItems[i].count;
            }
        }
        return arr;
    }

    const clearCartItem = () => {
        StorageHelper.setCart([])
        setCartItems([]);
    };

    const contextValue = {
        cartItems,
        addToCart,
        updateToCart,
        removeFromCart,
        getTotalCartAmount,
        getTotalCartCount,
        getOneItemCount,
        clearCartItem,
    };

    useEffect(() => {
        StorageHelper.setCart(cartItems);
    }, [cartItems]);

    return (
        <CartContext.Provider value={contextValue}>
            {props.children}
        </CartContext.Provider>
    );
};