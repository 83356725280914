import React, { useState } from 'react';
import { useFormik } from "formik";

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { Toast } from '../Layout/Alert';

import ContactUsService from "../../services/ContactUsService";

import { ContactForm } from "../../helper/ValidationHelper";

function Contact() {

    const [isLoading, setIsLoading] = useState(false);

    const submitHandler = async (formvalues) => {
        try {
            setIsLoading(true);
            const response = await ContactUsService.create({ data: formvalues });
            if (response.status === 200) {
                setIsLoading(false);
                Toast.fire({
                    icon: 'success',
                    title: 'Thank you for getting in touch!'
                });
            } else {
                setIsLoading(false);
                Toast.fire({
                    icon: 'warning',
                    title: "Something went wrong. try again later"
                });
            }
        } catch (err) {
            setIsLoading(false);
            Toast.fire({
                icon: 'error',
                title: 'Something went wrong. try again later'
            })
        }
    };

    // form input  intial values
    const initialValues = {
        name: "",
        email_id: "",
        phone_numder: "",
        message: "",
    };
    //formik form validation
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: ContactForm,
        onSubmit: (values, action) => { submitHandler(values); action.resetForm() },
    });


    return (
        <>
            <Header />
            <div className='contact-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-6 offset-md-3'>
                            <div className='contact-form-wrap'>
                                <div className='comon-title mb-4'>
                                    <h3>Contact Us</h3>
                                </div>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className='contact-form-inner'>
                                        <div className="form-group mb-3">
                                            <label>Name *</label>
                                            <input
                                                type="text"
                                                placeholder=""
                                                name="name"
                                                autoComplete="off"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                                className="form-control"
                                            />
                                            {formik.touched.name && formik.errors.name ? (
                                                <p className="text-danger small"><strong>{formik.errors.name}</strong></p>
                                            ) : null}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label>Email *</label>
                                            <input
                                                type="email"
                                                placeholder="example@gmail.com"
                                                name="email_id"
                                                autoComplete="off"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email_id}
                                                className="form-control"
                                            />
                                            {formik.touched.email_id && formik.errors.email_id ? (
                                                <p className="text-danger small "><strong>{formik.errors.email_id}</strong></p>
                                            ) : null}
                                        </div>
                                        <div className='form-group mb-3'>
                                            <label>Phone Numder *</label>
                                            <input
                                                type="text"
                                                placeholder="1234567890"
                                                name="phone_numder"
                                                autoComplete="off"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phone_numder}
                                                className="form-control"
                                            />
                                            {formik.touched.phone_numder && formik.errors.phone_numder ? (
                                                <p className="text-danger small "><strong>{formik.errors.phone_numder}</strong></p>
                                            ) : null}
                                        </div>
                                        <div className='form-group mb-3'>
                                            <label>Message *</label>
                                            <textarea
                                                placeholder=""
                                                name="message"
                                                autoComplete="off"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.message}
                                                className="form-control"
                                                rows='5'
                                                id='message'>
                                            </textarea>
                                            {formik.touched.message && formik.errors.message ? (
                                                <p className="text-danger small "><strong>{formik.errors.message}</strong></p>
                                            ) : null}
                                        </div>
                                        <div className='form-group'>
                                            {/* <input className='btn btn-warning' type='submit' name='' value='Send Message' /> */}
                                            <button disabled={isLoading} type='submit' className="btn btn-warning">
                                                {isLoading ?
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> : "Send Message"}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact;