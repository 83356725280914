import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class ContactUsService {

    create(payload) {
        return axios.post(HttpHelper.STRAPI_API_URL + '/contact-uses', payload, HttpHelper.getHeader());
    }

    send(payload) {
        return axios.post(HttpHelper.BACKEND_API_URL + '/contact-uses/sendemail', payload, HttpHelper.getHeader());
    }

}

export default new ContactUsService();