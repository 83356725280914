import React, { } from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <>
            <footer className='footer-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-6 col-lg-3 mb-4'>
                            <div className='footer-inner-wrap'>
                                <h3>Use Full Link</h3>
                                <div className='footer-menu'>
                                    <ul>
                                        <li>
                                            <Link to='/'>Catering</Link>
                                        </li>
                                        <li>
                                            <Link to='/'>Reservations</Link>
                                        </li>
                                        <li>
                                            <Link to='/'>Gift Cards</Link>
                                        </li>
                                        <li>
                                            <Link to='/'>Check Gift Card Balance</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 mb-4'>
                            <div className='footer-inner-wrap'>
                                <h3>Contact Us</h3>
                                <div className="contact-det-list">
                                    <span><i className="fa fa-map-marker" aria-hidden="true"></i></span>
                                    <p>The Yellow Chilli Tustin 2463 Park Ave, Tustin, CA 92782</p>
                                </div>
                                <div className="contact-det-list">
                                    <span><i className="fa fa-phone" aria-hidden="true"></i></span>
                                    <p><a href="tel:+1 949 449 5882">+1 949 449 5882</a></p>
                                </div>
                                <div className="contact-det-list">
                                    <span><i className="fa fa-envelope" aria-hidden="true"></i></span>
                                    <p><a href="mailto:praveen@tyctustin.com">praveen@tyctustin.com</a></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 mb-4'>
                            <div className='footer-inner-wrap'>
                                <h3>Opening Hours</h3>
                                <div className='opening-hours-list'>
                                    <div className='name'>
                                        <h5>Mon to Fri:</h5>
                                    </div>
                                    <div className='content'>
                                        <p>11:30 AM - 2:30 PM 5:00 PM - 9:30 PM</p>
                                    </div>
                                </div>
                                <div className='opening-hours-list'>
                                    <div className='name'>
                                        <h5>Sat:</h5>
                                    </div>
                                    <div className='content'>
                                        <p>12:00 PM - 3:00 PM 5:30 PM - 10:30 PM</p>
                                    </div>
                                </div>
                                <div className='opening-hours-list'>
                                    <div className='name'>
                                        <h5>Sun:</h5>
                                    </div>
                                    <div className='content'>
                                        <p>12:00 PM - 3:00 PM 5:00 PM - 9:30 PM</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 mb-4'>
                            <div className='footer-inner-wrap'>
                                <div className='map-wrap'>
                                    <img className='img-fluid' src='/images/google-map.jpg' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bottom-footer'>
                    <div className='container'>
                        <div className='row flex-md-row-reverse'>
                            <div className='col-12 col-md-8'>
                                <div className='policy-menu'>
                                    <ul>
                                        <li>
                                            <Link to='/'>Privacy Policy</Link>
                                        </li>
                                        <li>
                                            <Link to='/'>Terms & Conditions</Link>
                                        </li>
                                        <li>
                                            <Link to='/'>Copyright Policy</Link>
                                        </li>
                                        <li>
                                            <Link to='/'>Accessibility Statement</Link>
                                        </li>
                                        <li>
                                            <Link to='/'>Share Feedback</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-12 col-md-4'>
                                <div className='copyright'>
                                    <p>© Copyright 2022. All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;