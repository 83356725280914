import React, { } from 'react';
import { Link } from 'react-router-dom';
import HttpHelper from "../../../helper/HttpHelper";

function Products(props) {
    const url = HttpHelper.STRAPI_URL;

    let Products = props.data;

    return (
        <div className='row'>
            {Products && Products.map((row) => {
                let prodetailsurl = "/products/" + row.id;
                return (
                    <div key={row.id} className='col-12 col-md-6 col-lg-4 mb-4'>
                        <Link to={prodetailsurl}>
                            <div className='product-wrap'>
                                <div className='image-box'>
                                    {row.attributes.images.data.length && <img src={url + row.attributes.images?.data[0]?.attributes.formats.thumbnail.url} />}
                                </div>
                                <div className='prd-content'>
                                    <div className='rating-img'>
                                        <img src={'/images/rating-img1.png'} />
                                    </div>
                                    <h3>{row.attributes.name}</h3>
                                    <h4>$ {row.attributes.actual_price}</h4>
                                    <Link className='btn btn-warning' to={prodetailsurl}>Order Now</Link>
                                </div>
                            </div>
                        </Link>
                    </div>
                );
            })}
        </div>
    )
}

export default Products;