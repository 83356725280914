import React, { useState, useEffect } from 'react';
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import HttpHelper from "../../helper/HttpHelper";
import AboutService from "../../services/AboutService";

function About() {

    const url = HttpHelper.STRAPI_URL;

    const [loding, setLoding] = useState(true);
    let [eventDetails, setEventDetails] = useState([]);

    async function getOneEvent() {
        let query = {
            populate: "*"
        };
        const response = await AboutService.getAll(query);
        if (response.status === 200) {
            console.log(response.data.data[0]);
            setEventDetails(response.data.data[0]);
            setLoding(false);
        }
    }

    useEffect(() => {
        try {
            getOneEvent();
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <>
            <Header />
            <section className="about-us-wrapper">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-4">
                            {!loding &&
                                <div className="event-img">
                                    <img className="img-fluid" src={url + eventDetails.attributes.media?.data.attributes.url} />
                                </div>
                            }
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="cms-content">
                                {!loding &&
                                    <>
                                        <h3>{eventDetails.attributes.headline}</h3>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: eventDetails.attributes.paragraph }}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default About;