import { createContext, useState } from "react";
import StorageHelper from "../helper/StorageHelper";

export const UserContext = createContext(null);

export const UserContextProvider = (props) => {
    const [user, setUser] = useState(StorageHelper.getUser());
    const [token, setToken] = useState(StorageHelper.getToken());

    const updateUser = (data) => {
        setUser(data);
        StorageHelper.setUser(data);
    }

    const updateToken = (data) => {
        setToken(data);
        StorageHelper.setToken(data);
    }

    const isLogin = () => {
        return (token) ? true : false;
    }

    const contextValue = {
        user,
        token,
        isLogin,
        updateUser,
        updateToken
    };

    return (
        <UserContext.Provider value={contextValue}>
            {props.children}
        </UserContext.Provider>
    );
};