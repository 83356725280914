import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class EventService {

    getAll(payload) {
        return axios.get(HttpHelper.STRAPI_API_URL + '/events', { params: payload }, HttpHelper.getHeader());
    }

    getOne(id, payload) {
        return axios.get(HttpHelper.STRAPI_API_URL + '/events/' + id, { params: payload }, HttpHelper.getHeader());
    }

}

export default new EventService();