import React, { useState } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { Toast } from '../../Layout/Alert';

import AuthService from "../../../services/AuthService";

import { ForgotPasswordForm } from "../../../helper/ValidationHelper";

function ForgotPassword() {

    const [isLoading, setIsLoading] = useState(false);

    const submitHandler = async (formvalues) => {
        try {
            setIsLoading(true);
            const response = await AuthService.ForgotPassword(formvalues);
            if (response.status === 200) {
                setIsLoading(false);
                Toast.fire({
                    icon: 'success',
                    title: 'your reset password link send your email'
                });
            } else {
                setIsLoading(false);
                Toast.fire({
                    icon: 'warning',
                    title: "Something went wrong. try again later"
                });
            }
        } catch (err) {
            setIsLoading(false);
            let message = err.message;
            if (err.code === "ERR_BAD_REQUEST") {
                if (err.response.data.error) {
                    message = err.response.data.error.message;
                }
            }
            Toast.fire({ icon: 'error', title: message });
        }
    };

    // form input  intial values
    const initialValues = {
        email: "",
    };

    //formik form validation
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: ForgotPasswordForm,
        onSubmit: (values, action) => {
            submitHandler(values);
            action.resetForm()
        },
    });


    return (
        <>
            <Header />
            <section className="register-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 offset-md-3">
                            <div className="sign-form-wrap">
                                <div className="login-title mb-4">
                                    <div className="log-logo">
                                        <img className="img-fluid" src="./images/logo.png" />
                                    </div>
                                    <h3>Forgot Password</h3>
                                </div>
                                <div className="signin-form">
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="row">
                                            <div className="form-group mb-3">
                                                <label>Email *</label>
                                                <input
                                                    type="email"
                                                    placeholder="example@gmail.com"
                                                    name="email"
                                                    autoComplete="off"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                    className="form-control"
                                                />
                                                {formik.touched.email && formik.errors.email ? (
                                                    <p className="text-danger small "><strong>{formik.errors.email}</strong></p>
                                                ) : null}
                                            </div>


                                            <div className="col-12 col-md-12">
                                                <div className="form-group submit-btn-wrap">
                                                    <ul>
                                                        <li>
                                                            <button disabled={isLoading} type='submit' className="btn btn-warning">
                                                                {isLoading ?
                                                                    <div className="spinner-border" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div> : "Reset"}
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <p> Already have an account? <Link to="/login">Sign In </Link></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}


export default ForgotPassword;