import React, { useContext, useState } from 'react';
import { CartContext } from "../../../provider/CartContext";
import HttpHelper from "../../../helper/HttpHelper";

function CartItems(props) {
    const url = HttpHelper.STRAPI_URL;
    const { addToCart, updateToCart, removeFromCart, cartItems, getOneItemCount, } = useContext(CartContext);

    let [data, setData] = useState(props.data);
    let [count, setCount] = useState(props.data.count || 1);

    console.log(data);

    function update(item) {
        let data = item;
        data.count = count;
        data.subtotal = data.attributes.actual_price * count;
        updateToCart(data);
    }

    function incrementCount(item) {
        if (count >= 0) {
            count = count + 1;
            setCount(count);
            update(item);
        }

    }
    function decrementCount(item) {
        if (count > 1) {
            count = count - 1;
            setCount(count);
            update(item);
        } else {
            // setCount(0);
            // removeFromCart(item.id);
        }
    }

    return (

        <tr>
            <td>
                <div className='cart-product'>
                    <div className='prd-img'>
                        <img className='img-fluid' src={data.attributes.images.data.length && url + data.attributes.images.data[0].attributes.formats.thumbnail.url} />
                    </div>
                    <div className='prd-title'>
                        <h4>{data.attributes.name}</h4>
                        <h5>SIZE: Normal</h5>
                    </div>
                </div>
            </td>
            <td>
                <p>${data.attributes.actual_price}</p>
            </td>
            <td>
                <div className="product-summery">
                    <div className="count-bx">
                        <button onClick={() => decrementCount(data)}>-</button>
                        <div className='count'>{count}</div>
                        <button onClick={() => incrementCount(data)}>+</button>
                    </div>
                </div>
            </td>
            <td>
                <h5>${data.subtotal}</h5>
            </td>
            <td>
                <span onClick={() => removeFromCart(data.id)} className='product-remove'>
                    <i className='fa fa-times-circle' aria-hidden='true'></i>
                </span>
            </td>
        </tr>


    );
}
export default CartItems;