import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class CategoryService {

    getAll(payload) {
        return axios.get(HttpHelper.STRAPI_API_URL + '/categories', { params: payload }, HttpHelper.getHeader());
    }

}

export default new CategoryService();