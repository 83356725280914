import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import { UserContext } from "../../provider/UserContext";
import UserService from "../../services/UserService";
import HttpHelper from "../../helper/HttpHelper";

function Profile() {

    const url = HttpHelper.STRAPI_URL;

    const { user, updateUser } = useContext(UserContext);

    const thumbnailurl = user.images ? url + user.images.formats.thumbnail.url : "./images/auth-img1.jpg";
    // console.log(url + user.images.formats.thumbnail.url);

    const [inputs, setInputs] = useState({
        mobile_number: user.mobile_number,
        username: user.username,
        email: user.email,
    });
    const [error, seterror] = useState('');

    // async function handleSubmit() {
    //     try {
    //         const response = await UserService.update(user.id, inputs);
    //         if (response.status == 200) {
    //             console.log(response.data);
    //             updateUser(response.data);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //         if (error && error.response.status == 400) {
    //             seterror(error.response.data.error.message);
    //         }
    //     }
    // }

    const [selectedImage, setselectedImage] = useState(false);
    const onFileChangeImages = (event) => {
        setselectedImage(event.target.files[0]);
    };

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            var formData = new FormData();
            formData.append("data", JSON.stringify(inputs));
            if (selectedImage) {
                formData.append("files.images", selectedImage);
            }
            let response = await UserService.update(user.id, formData);
            if (response.status === 200) {
                let query = {
                    populate: "*",
                };
                response = await UserService.getOne(user.id, query);
                if (response.status === 200) {
                    console.log(response.data);
                    updateUser(response.data);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <Header />
            <section className="user-db-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-2">
                            {/* <form> */}
                            <div className="user-profile-wrap">
                                <div className="row">
                                    <div className="col-12 mb-4">
                                        <div className="profile-img-wrap">
                                            <div className="profile-left">
                                                <div className="profile-img">
                                                    <img className="img-fluid" src={thumbnailurl} alt="" />
                                                </div>
                                                <div className="profile-choose-file">
                                                    <input type="file" id="file" name="images" onChange={onFileChangeImages} />
                                                    <label htmlFor="file"><img className="img-fluid" src="./images/camera-icon.png" alt="" /></label>
                                                </div>
                                            </div>
                                            <div className="profile-img-info">
                                                <p>Maximum file size : 1.5MB</p>
                                                <p>Allowed file types : jpg, png, jpeg, gif</p>
                                                <p>Preferred size: Width:101px Height:101px</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-12 col-md-6 mb-2">
                                            <div className="form-group">
                                                <label className="com-label">Gender</label>
                                                <ul className="gender-list">
                                                    <li>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="GenderRadios" id="Male_Radios" defaultValue="option1" defaultChecked={genderRadios} />
                                                            <label className="form-check-label" htmlFor="Male_Radios">
                                                                Male
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="GenderRadios" id="Female_Radios" value="option2" />
                                                            <label className="form-check-label" htmlFor="Female_Radios">
                                                                Female
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="GenderRadios" id="Trangender_Radios" value="option3" />
                                                            <label className="form-check-label" htmlFor="Trangender_Radios">
                                                                Trangender
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                                    <div className="col-12 col-md-6 mb-2">
                                        <div className="form-group">
                                            <label className="com-label">Name</label>
                                            <input type="text" className="form-control" placeholder="Name" name="username" value={inputs.username} readOnly />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <div className="form-group">
                                            <label className="com-label">Email Id</label>
                                            <input type="email" className="form-control" placeholder="Email" name="email" value={inputs.email} readOnly />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <div className="form-group">
                                            <label className="com-label">Mobile Number</label>
                                            <input type="text" className="form-control" placeholder="Mobile Number" name="mobile_number" defaultValue={inputs.mobile_number} onChange={handleChange} />
                                        </div>
                                    </div>
                                    {/* <div className="col-12 col-md-12 mb-2">
                                            <div className="form-group">
                                                <label className="com-label">Address</label>
                                                <textarea className="form-control" placeholder="|" rows="1">{user.username}</textarea>
                                            </div>
                                        </div> */}
                                    <div className="col-12 mt-3">
                                        <div className="form-group">
                                            <input onClick={handleSubmit} className="btn btn-warning" type="submit" value="Save Changes" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Profile;