import { Route, Routes } from "react-router-dom";
import Home from '../components/pages/Home';
import Contact from '../components/pages/Contact';
import Shop from '../components/pages/Shop/Shop';
import ProductDetails from '../components/pages/Shop/ProductDetails';
import Cart from '../components/pages/Shop/Cart';
import About from '../components/pages/About';
import Events from '../components/pages/Event/Events';
import EventDetails from '../components/pages/Event/EventDetails';
import Reservations from '../components/pages/Reservations';
import GiftCards from '../components/pages/GiftCards/GiftCards';
import PaymentStatus from '../components/pages/GiftCards/PaymentStatus';
import RewardsLogin from '../components/pages/RewardsLogin';
import FoodMenu from '../components/pages/Shop/FoodMenu';
import Order from '../components/pages/Shop/Order';
import Register from '../components/pages/Auth/Register';
import Login from '../components/pages/Auth/Login';
import ForgotPassword from '../components/pages/Auth/ForgotPassword';
import ResetPassword from '../components/pages/Auth/ResetPassword';
import ChangePassword from '../components/pages/Auth/ChangePassword';
import Profile from '../components/pages/Profile';
import { RequireAuth, RequireAuthLogout } from '../middleware/Authenticate';

function RouteList() {
  // console.log("RouteList");
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/contact" element={<Contact />} exact />
        <Route path="/shop" element={<Shop />} exact />
        <Route path="/shop/:name" element={<Shop />} exact />
        <Route path="/products" element={<ProductDetails />} exact />
        <Route path="/products/:id" element={<ProductDetails />} exact />
        <Route path="/cart" element={<Cart />} exact />
        <Route path='/about' element={<About />} exact />
        <Route path='/events' element={<Events />} exact />
        <Route path='/events/:id' element={<EventDetails />} exact />
        <Route path='/reservations' element={<Reservations />} exact />
        <Route path='/giftcards' element={<RequireAuth><GiftCards /></RequireAuth>} exact />
        <Route path='/giftcards/complete' element={<PaymentStatus />} exact />
        <Route path='/rewardslogin' element={<RewardsLogin />} exact />
        <Route path='/foodmenu' element={<FoodMenu />} exact />
        <Route path='/order' element={<Order />} exact />
        <Route path='/register' element={<RequireAuthLogout><Register /></RequireAuthLogout>} exact />
        <Route path='/login' element={<RequireAuthLogout><Login /></RequireAuthLogout>} exact />
        <Route path='/forgot-password' element={<RequireAuthLogout><ForgotPassword /></RequireAuthLogout>} exact />
        <Route path='/reset-password' element={<RequireAuthLogout><ResetPassword /></RequireAuthLogout>} exact />
        <Route path='/profile' element={<RequireAuth><Profile /></RequireAuth>} exact />
        <Route path='/change-password' element={<RequireAuth><ChangePassword /></RequireAuth>} exact />
      </Routes>
    </>
  );
}

export default RouteList;
