import React, { useState, useEffect } from 'react';
import InnerBanner from '../../Layout/InnerBanner';
import { Link, useParams } from 'react-router-dom';

import Footer from '../../Layout/Footer'
import Header from '../../Layout/Header';
import Categories from './Categories';
import Products from './Products';

import ProductService from "../../../services/ProductService";
import CategoryService from "../../../services/CategoryService";

function Shop() {

    const { name } = useParams();

    const [category, setCategory] = useState([]);
    const [product, setProduct] = useState([]);
    const [loding, setLoding] = useState(true);

    async function allProduct() {
        let query = {
            populate: ['images'],
        };
        if (name) {
            query = {
                filters: {
                    category: {
                        slug: {
                            $eq: name
                        },
                    }
                },
                populate: ['images'],
            };
        };
        const response = await ProductService.getAll(query);
        if (response.status == 200) {
            setProduct(response.data.data);
            setLoding(false);
        }
    }

    async function allCategory() {
        let query = {};
        const response = await CategoryService.getAll(query);
        if (response.status == 200) {
            setCategory(response.data.data);
        }
    }

    useEffect(() => {
        console.log(name);
        try {
            allCategory();
            allProduct();
        } catch (error) {
            console.log(error);
        }
    }, [name]);

    return (
        <>
            <Header />
            <InnerBanner />
            <section className='shop-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-4 col-lg-3 mb-3 mb-md-0'>
                            <Categories data={category}></Categories>
                        </div>
                        <div className='col-12 col-md-8'>
                            <Products data={product}></Products>
                        </div>
                        {/* <div className='col-12 text-center'>
                            <Link className='btn btn-outline-danger'>Load More</Link>
                        </div> */}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default Shop;