import React, { } from 'react';
import { Link } from 'react-router-dom';

function InnerBanner() {
    return (
        <>
            <div className='inner-banner'>
                <img className='img-fluid' src='/images/inner-banner.jpg' />
                <div className='inner-banner-wrap'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='inner-banner-content'>
                                    <h4>The Yellow</h4>
                                    <h3>Chilli Tustin</h3>
                                    <ul>
                                        <li>
                                            <p><span><i className='fa fa-map-marker' aria-hidden='true'></i></span> The Yellow Chilli Tustin 2463 Park Ave, Tustin, CA 92782</p>
                                        </li>
                                        <li><p><span><i className='fa fa-clock-o' aria-hidden='true'></i></span> Open Until 8:30 PM</p></li>
                                    </ul>
                                    <Link className='btn btn-warning' to="/">Order Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default InnerBanner;