import StorageHelper from '../helper/StorageHelper';
import { Navigate, useLocation } from 'react-router-dom';
import React from 'react'

export const RequireAuth = ({ children }) => {
    const token = StorageHelper.getToken();
    const location = useLocation();

    if (!token) {
        return <Navigate to="/login" state={{ path: location.pathname }} />
    }
    return children;
}

export const RequireAuthLogout = ({ children }) => {
    const token = StorageHelper.getToken();
    const location = useLocation();

    if (token) {
        return <Navigate to="/profile" state={{ path: location.pathname }} />
    }
    return children;
}