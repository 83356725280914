import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import CartItems from './CartItems';

import { CartContext } from "../../../provider/CartContext";
import { UserContext } from "../../../provider/UserContext";

function Cart() {

    const { isLogin } = useContext(UserContext);
    const { getTotalCartAmount, cartItems } = useContext(CartContext);

    let carts = cartItems || [];
    let next = (isLogin()) ? '/order' : '/login';

    // console.log(carts);

    return (
        <>
            <Header />
            <section className='cart-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 mb-4'>
                            <div className='comon-title'>
                                <h3>Cart</h3>
                            </div>
                        </div>

                        {carts.length > 0 &&
                            <>
                                <div className='col-12 col-md-8 mb-4'>
                                    <div className='product-cart-wrap'>
                                        <div className='table-responsive cart-table'>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Price</th>
                                                        <th>Quantity</th>
                                                        <th>Subtotal</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {carts.map((row, id) => {
                                                        return <CartItems key={id} data={row}></CartItems>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <div className='apply-coupon-wrap'>
                                    <div className='coupon-box'>
                                        <input type='text' className='form-control' placeholder='Coupon code' />
                                    </div>
                                    <div className='coupon-btn'>
                                        <input className='btn btn-warning' type='submit' value='Apply' />
                                    </div>
                                </div> */}
                                    </div>
                                </div>
                                <div className='col-12 col-md-4'>
                                    <div className='cart-right-side'>
                                        <div className='subtotal-wrap'>
                                            <div className='subtotal'>
                                                <div className='name'>
                                                    <h5>Subtotal</h5>
                                                </div>
                                                <div className='price'>
                                                    <h4>${getTotalCartAmount()}</h4>
                                                </div>
                                            </div>
                                            <div className='total'>
                                                <div className='name'>
                                                    <h5>Total</h5>
                                                </div>
                                                <div className='price'>
                                                    <h4>${getTotalCartAmount()}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='checkout-btn'>
                                            <Link className='btn btn-warning' to={next}> Proceed to checkout </Link>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {carts.length < 1 &&
                            <div className='col-12-xs'>
                                <h3 style={{width:'100%', textAlign:'center'}}>Your Cart is empty</h3>
                            </div>
                        }

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Cart;