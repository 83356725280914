import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import HttpHelper from '../../helper/HttpHelper';
import { UserContext } from "../../provider/UserContext";
import { CartContext } from "../../provider/CartContext";

function MenuAlignEndExample() {

    const { user } = useContext(UserContext);

    return (
        <DropdownButton
            align="end"
            title={user?.username}
            id="dropdown-menu-align-end"
            variant="success"
        >
            <Dropdown.Item eventKey="2"><Link to='/orders'><i className="las la-box"></i> Orders</Link></Dropdown.Item>
            <Dropdown.Item eventKey="3"><Link to='/profile'><i className="las la-user"></i> Profile</Link></Dropdown.Item>
            <Dropdown.Item eventKey="3"><Link to='/change-password'><i className="las la-key"></i> Change Password</Link></Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="4"><Link onClick={() => (HttpHelper.Logout())}><i className="las la-sign-out-alt"></i> Logout</Link></Dropdown.Item>
        </DropdownButton>
    );
}

function Header() {

    // cartItems
    const { getTotalCartCount } = useContext(CartContext);
    let totalCartCount = getTotalCartCount();

    // login
    // let isLogin = HttpHelper.isLogin();

    const { token } = useContext(UserContext);

    return (
        <>
            <header className='header-wrapper'>
                <div className='top-header'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-12 col-lg-4 mb-2 mb-lg-0'>
                                <div className='head-cont-info'>
                                    <ul>
                                        <li>
                                            <span className='icon'>
                                                <i className='fa fa-phone' aria-hidden='true'></i>
                                            </span>
                                            <a href='tel:+19494495882'>+19494495882</a>
                                        </li>
                                        <li>
                                            <span className='icon'>
                                                <i className='fa fa-envelope' aria-hidden='true'></i>
                                            </span>
                                            <a href='mailto:praveen@tyctustin.com'>praveen@tyctustin.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-12 col-lg-5 mb-2 mb-lg-0'>
                                <div className='get-off-text'>
                                    <p>Get 20% off your first order with code: LOCAL at checkout. Exclusions Apply.</p>
                                </div>
                            </div>
                            <div className='col-12 col-lg-3 mb-2 mb-lg-0'>
                                <div className='social-media'>
                                    <ul>
                                        <li>
                                            <Link to='/'>
                                                <i className='fa fa-facebook' aria-hidden='true'></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/'>
                                                <i className='fa fa-instagram' aria-hidden='true'></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/'>
                                                <i className='fa fa-google' aria-hidden='true'></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/'>
                                                <i className='fa fa-yelp' aria-hidden='true'></i>
                                            </Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='header-menu'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <nav className='navbar navbar-expand-lg navbar-light'>
                                    <div className='logo-wrap'>
                                        <Link className='navbar-brand' to='/'>
                                            <img className='img-fluid' src="./images/The-Yellow-Spices-logo-01.png" alt="" />
                                        </Link>
                                    </div>

                                    <div className='collapse navbar-collapse justify-content-end' id='navbarNav'>
                                        <ul className='navbar-nav'>
                                            <li className='active'>
                                                <Link to='/shop'>Menu</Link>
                                            </li>
                                            <li>
                                                <Link to='/reservations'>Reservations</Link>
                                            </li>
                                            <li>
                                                <Link to='/about'>About</Link>
                                            </li>
                                            <li>
                                                <Link to='/events'>Events</Link>
                                            </li>
                                            <li>
                                                <Link to='/giftcards'>Gift Cards</Link>
                                            </li>
                                            <li>
                                                <Link to='/'>Directions</Link>
                                            </li>
                                            <li>
                                                <Link to='/contact'>Contact</Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='hed-order-btn'>
                                        <ul>
                                            <li>
                                                <Link className='btn btn-outline-danger' to='/shop'>Order Now</Link>
                                            </li>
                                            <li>
                                                <div className='cart'>
                                                    <Link to='/cart'>
                                                        <i className='las la-shopping-bag'></i>
                                                        <span className='count'> {totalCartCount} </span>
                                                    </Link>
                                                </div>
                                            </li>
                                            {token
                                                ? <li><MenuAlignEndExample /></li>
                                                : <li><Link className='login-btn' to='/login'><i className="las la-user"></i></Link></li>
                                            }
                                        </ul>

                                    </div>


                                    <button className='navbar-toggler' type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="fa fa-bars"></span>
                                    </button>

                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    );
}

export default Header;