/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import './event.css';

import HttpHelper from "../../../helper/HttpHelper";
import EventService from "../../../services/EventService";


function Events() {

    const url = HttpHelper.STRAPI_URL;

    const [loding, setLoding] = useState(true);
    let [events, setEvents] = useState([]);

    async function allEvent() {
        let query = {
            populate: "*"
        };
        const response = await EventService.getAll(query);
        if (response.status === 200) {
            setEvents(response.data.data);
            setLoding(false);
        }
    }

    useEffect(() => {
        try {
            allEvent();
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <>
            <Header />
            <section className="event-wrapper">
                <div className="container">
                    <div className="row">
                        {!loding && events.map((row, i) => {
                            console.log(row);
                            let event_url = '/events/' + row.id;
                            return (
                                <div key={i} className="col-12 col-md-4 mb-4">
                                    <Link to={event_url}>
                                        <div className="event-list">
                                            {row.attributes.media.data &&
                                                <div className="event-img">
                                                    <img className="img-fluid" src={url + row.attributes.media?.data.attributes.url} />
                                                </div>
                                            }
                                            <div className="event-content">
                                                <h4>{row.attributes.title}</h4>
                                                <Link className="btn btn-warning" to={event_url}>Read More</Link>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Events;