import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class PaymentsService {

    createPaymentIntent(payload) {
        return axios.post(HttpHelper.BACKEND_API_URL + '/stripe/create-payment-intent', payload, HttpHelper.getHeader());
    }

}

export default new PaymentsService();