import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import HttpHelper from '../../../helper/HttpHelper';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: HttpHelper.WEB_URL + '/giftcards/complete',
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <div className="giftcard-inner-wrap">

      <form onSubmit={handleSubmit}>
        <div className="title mb-3">
          <h3>Gift Card Checkout</h3>
        </div>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <div className="form-group mt-3">
          <button disabled={isLoading || !stripe || !elements} id="submit" className="btn-lg btn-default form-control">
            {isLoading ?
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div> : "Pay now"}
          </button>
        </div>
        {/* Show error message to your customers */}
        {message && <div>{message}</div>}
      </form>
    </div>
  )
};

export default CheckoutForm;