/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Layout/Footer'
import Header from '../Layout/Header';
import SliderProducts from '../Layout/SliderProducts';
import Slider from "react-slick";

import PickProductService from "../../services/PickProductService";

const TestimonialsSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }

    ]
};

function Home() {

    const [products, setProducts] = useState([]);
    const [loding, setLoding] = useState(true);

    async function getPicksProduct() {
        let query = {
            populate: {
                product: {
                    populate: ['images'],
                },
            },
        };
        const response = await PickProductService.getAll(query);
        if (response.status === 200) {
            setProducts(response.data.data);
            setLoding(false);
        }
    }

    useEffect(() => {
        try {
            getPicksProduct();
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <>
            <Header />

            <section className='banner-wrapper'>
                <img className='img-fluid' src="./images/banner-images1.jpg" alt="" />
                <div className='banner-content'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <h2>Best Indian Restaurant </h2>
                                <h1>In Tustin</h1>
                                <p>Order takeout & delivery from our site to save money and support <br /> local business. Get the best price, fastest service, and free food via <br /> rewards points.</p>
                                <a className='btn btn-outline-warning' href="#"> Book A Table </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {!loding &&
                <SliderProducts title="Our Top Picks" products={products}></SliderProducts>
            }

            <section className='order-directly-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-lg-7'>
                            <div className='row'>
                                <div className='col-12 col-md-6 mb-3'>
                                    <div className='order-directly-img'>
                                        <img className='img-fluid' src={'./images/order-directly-img1.jpg'} />
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 mb-3'>
                                    <div className='order-directly-img'>
                                        <img className='img-fluid' src={'./images/order-directly-img2.jpg'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-5'>
                            <div className='order-directly-website'>
                                <div className='comon-title mb-3'>
                                    <h3>Order Directly On Our Website</h3>
                                </div>
                                <p><span>1.</span> Earn rewards points, save money, get your food delivered faster and support local business.</p>
                                <p><span>2.</span> Offering a beautiful setting for any occasion, reserve a table and let us share our space with you.</p>
                                <p><span>3.</span> Sip craft cocktails, specialty drinks and delectable bites in our spacious bar for a truly enjoyable experience! There is a full range of bar drinks including a wide selection of spirits, liqueurs, beers on tap, wine and soft drinks.</p>
                                <Link className='btn btn-warning' to='/shop'>Order Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='visit-us-wrapper'>
                <img className='img-fluid' src={'./images/visit-us-bg.jpg'} />
                <div className='visit-us-content-wrap'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-6 offset-md-3 text-center'>
                                <div className='play-btn'>
                                    <img className='img-fluid' src={'./images/play-button.png'} />
                                </div>
                                <h4>Visit Us</h4>
                                <p>We invest in quality ingredients to ensure our customers get the great taste we’re famous for. Because we believe that you deserve the best.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className='finest-dishes-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-lg-5 mb-4 mb-lg-0'>
                            <div className='finest-dishes-content'>
                                <div className='comon-title mb-3'>
                                    <h3>The Taste Of India's Finest Dishes</h3>
                                </div>
                                <p>Savor the real taste of India's finest dishes and enjoy a wonderful dining experience at The Yellow Chilli Tustin by Master Chef Sanjeev Kapoor. Enjoy exotic dishes from various regions of Punjab and the Mughal era. Discover our signature dishes like Lalla Mussa Dal, Shahi Tukda, Gulab-e-Gulkand, Murgh Noormahal Biryani, Nizami Tarkari Biryani, and much more!</p>
                                <p>He is one of the most recognized faces of Indian television. He is in people’s living rooms for almost two decades introducing them to the delights of Indian and international cuisine in his inimitable easy going and cheery manner.</p>
                                <Link className='btn btn-warning' to='/shop'>Order Now</Link>
                            </div>
                        </div>
                        <div className='col-12 col-lg-7'>
                            <div className='row'>
                                <div className='col-12 col-md-6 mb-3'>
                                    <div className='finest-dishes-img'>
                                        <img className='img-fluid' src={'./images/finest-dishes-img1.jpg'} />
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 mb-3'>
                                    <div className='finest-dishes-img'>
                                        <img className='img-fluid' src={'./images/finest-dishes-img2.jpg'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='testimonials-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 mb-4'>
                            <div className='comon-title'>
                                <h3>Some Things Happy Customers Have Said</h3>
                            </div>
                        </div>
                        <div className='col-12'>
                            <Slider {...TestimonialsSlider} >
                                <div className="testimonials-sld-items">
                                    <div className='testimonials-wrap'>
                                        <div className='double-quotes'>
                                            <img className='img-fluid' src='./images/double-quotes.png' />
                                        </div>
                                        <div className='content'>
                                            <p>The food was delicious and the presentation was amazing. I would say this is one of the best restaurants in town and it lives up to the expectations of a Sanjay Kumar restaurant. The dishes that i tasted were all very authentic and in right proportions</p>
                                            <h4>Vinay A</h4>
                                            <div className='rating'>
                                                <img src='./images/testimonials-rating-img.png' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonials-sld-items">
                                    <div className='testimonials-wrap'>
                                        <div className='double-quotes'>
                                            <img className='img-fluid' src='./images/double-quotes.png' />
                                        </div>
                                        <div className='content'>
                                            <p>Great place to have Indian food and hang out with friends and family. The staff is friendly and respectful. The flavors are unique and you can taste the freshness in food. Planner tikka for starters is one of my favorite dishes. Shaam Savera and Adraki dhaniya paneer is delicious and you would surely enjoy it.</p>
                                            <h4>Vaibhav O.</h4>
                                            <div className='rating'>
                                                <img src='./images/testimonials-rating-img.png' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonials-sld-items">
                                    <div className='testimonials-wrap'>
                                        <div className='double-quotes'>
                                            <img className='img-fluid' src='./images/double-quotes.png' />
                                        </div>
                                        <div className='content'>
                                            <p>The food was delicious and the presentation was amazing. I would say this is one of the best restaurants in town and it lives up to the expectations of a Sanjay Kumar restaurant. The dishes that i tasted were all very authentic and in right proportions</p>
                                            <h4>Vinay A</h4>
                                            <div className='rating'>
                                                <img src='./images/testimonials-rating-img.png' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}


export default Home;