import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class UserService {

    getAll(payload) {
        return axios.get(HttpHelper.STRAPI_API_URL + '/users', { params: payload }, HttpHelper.getAuthHeader());
    }

    getOne(id, payload) {
        return axios.get(HttpHelper.STRAPI_API_URL + '/users/' + id, { params: payload }, HttpHelper.getAuthHeader());
    }

    update(id, payload) {
        return axios.put(HttpHelper.STRAPI_API_URL + '/users/' + id, payload, HttpHelper.getAuthHeaderMultiPart());
    }

    changePassword(payload) {
        return axios.post(HttpHelper.STRAPI_API_URL + '/auth/change-password', payload, HttpHelper.getAuthHeader());
    }

}

export default new UserService();