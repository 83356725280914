import React from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Link } from "react-router-dom";


function RewardsLogin() {
    return (
        <>
            <Header />

            <section className="rewards-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 offset-md-3">
                            <div className="rewards-login-wrap">
                                <div className="head">
                                    <div className="log-logo">
                                        <img className="img-fluid" src="./images/logo.png" />
                                    </div>
                                    <h3>Look up rewards account</h3>
                                    <p>Please insert the email address or phone number associated with your account and we will get in touch with your account details.</p>
                                </div>
                                <div className="logbody">
                                    <div className="form-group mb-3">
                                        <input type="text" className="form-control" placeholder="Email address" />
                                    </div>
                                    <p>or</p>
                                    <div className="form-group mb-3">
                                        <input type="text" className="form-control" placeholder="Phone Number" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <input type="submit" className="btn btn-warning" value="Find now" />
                                    </div>
                                    <div className="form-group mb-3 text-center">
                                        <Link to="/">Join as a new member</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default RewardsLogin;