import * as Yup from "yup";

export const LoginForm = Yup.object().shape({
    identifier: Yup.string().email().required("Email is required"),
    password: Yup.string()
        .required("Password is required")
        .min(6, "Password is too short - should be 6 chars minimum")
        .max(64, "Password is too long - should be 30 chars maximun"),
});

export const RegisterForm = Yup.object().shape({
    username: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("User Name is required"),

    email: Yup.string().email().max(160).required("Email is required"),
    mobile_number: Yup.string()
        .required("Phone number is required")
        .matches(
            new RegExp('[0-9]{10}'),
            "Invalid phone number"
        )
        .max(10, "Number Should not be more than 10 character"),
    pin_code: Yup.string()
        .min(1, "Too Short!")
        .max(10, "Too Long!")
        .required("Pincode is required"),
    city: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("City is required"),
    password: Yup.string()
        .required("Password is required")
        .min(6, "Password is too short - should be 6 chars minimum")
        .max(64, "Password is too long - should be 30 chars maximun"),
    cpassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

export const ForgotPasswordForm = Yup.object().shape({
    email: Yup.string().email().max(160).required("Email is required"),
});

export const ResetPasswordForm = Yup.object().shape({
    password: Yup.string()
        .required("Password is required")
        .min(6, "Password is too short - should be 6 chars minimum")
        .max(64, "Password is too long - should be 30 chars maximun"),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

export const ChangePasswordForm = Yup.object().shape({
    currentPassword: Yup.string()
        .required("Password is required")
        .min(6, "Password is too short - should be 6 chars minimum")
        .max(64, "Password is too long - should be 30 chars maximun"),
    password: Yup.string()
        .required("Password is required")
        .min(6, "Password is too short - should be 6 chars minimum")
        .max(64, "Password is too long - should be 30 chars maximun"),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

export const ContactForm = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Name is required"),

    email_id: Yup.string().email().max(160).required("Email is required"),
    phone_numder: Yup.string()
        .required("Phone number is required")
        .matches(
            new RegExp('[0-9]{10}'),
            "Invalid phone number"
        )
        .max(10, "Number Should not be more than 10 character"),
    message: Yup.string()
        .min(5, "Too Short!")
        .max(200, "Too Long!")
        .required("Message is required"),
});

export const GiftCardsForm = Yup.object().shape({
    amount: Yup.number()
        .min(5, "Minimum amount is 5")
        .max(500, "maximum amount is 500")
        .required("amount is required"),
    to_email: Yup.string().email().max(160).required("Email is required"),
    delivary_date: Yup.string().required("Delivary date is required")
});