/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import SliderProducts from '../../Layout/SliderProducts';
import ProductService from "../../../services/ProductService";
import { CartContext } from "../../../provider/CartContext";
import HttpHelper from "../../../helper/HttpHelper";
import ReactStars from "react-rating-stars-component";


function ProductDetails() {
    const url = HttpHelper.STRAPI_URL;

    let { id } = useParams();

    const { addToCart, removeFromCart, getOneItemCount } = useContext(CartContext);

    let [oneItemCount, setOneItemCount] = useState(getOneItemCount(id) || 0);
    let [count, setCount] = useState(oneItemCount || 1);
    const [product, setProduct] = useState({});
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [loding, setLoding] = useState(true);
    const [ratingEdit, setRatingEdit] = useState(true);
    const [specialInstructions, setSpecialInstructions] = useState('');

    // function incrementCount() {
    //     if (count >= 0) {
    //         count = count + 1;
    //         setCount(count);
    //         addInToCart();
    //     }
    //     setOneItemCount(count);
    // }
    // function decrementCount() {
    //     if (count > 1) {
    //         count = count - 1;
    //         setOneItemCount(count);
    //         setCount(count);
    //         addInToCart()
    //     } else {
    //         setCount(0);
    //         removeFromCart(id);
    //         setOneItemCount(0);
    //     }
    // }

    async function allProduct() {
        if (id) {
            let query = {
                populate: ['images', 'related_products', 'related_products.images']
            };
            const response = await ProductService.getOne(id, query);
            if (response.status === 200) {
                console.log(response.data.data);
                setProduct(response.data.data);
                if ('related_products' in response.data.data.attributes) {
                    if (response.data.data.attributes.related_products.data.length) {
                        setRelatedProducts(response.data.data.attributes.related_products.data);
                    }
                }
                setLoding(false);
            }
        };
    }

    function addInToCart() {
        let prod = product;
        prod.special_instructions = specialInstructions;
        prod.count = count;
        prod.subtotal = prod.attributes.actual_price * count;
        addToCart(prod);
        setOneItemCount(count);
    }

    const ratingChanged = (newRating) => {
        console.log(newRating);
        setRatingEdit(false);
    };

    useEffect(() => {
        try {
            allProduct();
            setOneItemCount(getOneItemCount(id));
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <>
            <Header />
            {!loding &&
                <section className='product-details-wrapper'>
                    <div className='container'>
                        <div className='row'>

                            <div className='col-12 col-md-6 mb-4'>
                                <div className='product-image'>
                                    <img className='img-fluid' src={url + product.attributes.images.data[0].attributes.url} />
                                </div>
                            </div>

                            <div className='col-12 col-md-6'>
                                <div className='product-summery'>
                                    <h3>{product.attributes.name}</h3>
                                    <p>{product.attributes.description}</p>
                                    <div className='rating'>
                                        {/* <img src='/images/rating-img1.png' /> */}
                                        <ReactStars
                                            count={5}
                                            value={0}
                                            edit={ratingEdit}
                                            onChange={ratingChanged}
                                            size={24}
                                            activeColor="#FBB03B"
                                        />
                                    </div>
                                    <div className='price'>
                                        <h4>$ {product.attributes.actual_price}</h4>
                                    </div>
                                    <p>{product.attributes.description}</p>
                                    <h5>Special Instructions</h5>
                                    <div className='content-area'>
                                        <textarea onChange={(event) => setSpecialInstructions(event.target.value)} className='form-control' rows='4'></textarea>
                                    </div>
                                    {/* <h5>Quantity</h5> */}
                                    {/* <div className="count-bx">
                                        <button onClick={decrementCount}>-</button>
                                        <div className='count'>{count}</div>
                                        <button onClick={incrementCount}>+</button>
                                    </div> */}
                                    <div className='cart-btn'>
                                        {oneItemCount > 0 && <Link className='btn btn-warning' to='/cart'>Go To Cart</Link>}
                                        {oneItemCount < 1 && <input onClick={addInToCart} className='btn btn-warning' type="button" value="Add To Cart" />}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            }
            {relatedProducts.length &&
                <SliderProducts title="Related Products" products={relatedProducts}></SliderProducts>
            }
            <Footer />
        </>
    )
}

export default ProductDetails;