import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class GiftCardService {

    create(payload) {
        return axios.post(HttpHelper.STRAPI_API_URL + '/gift-cards', payload, HttpHelper.getAuthHeader());
    }

    update(id, payload) {
        return axios.put(HttpHelper.STRAPI_API_URL + '/gift-cards/' + id, payload, HttpHelper.getAuthHeader());
    }

    getOne(id, payload) {
        return axios.get(HttpHelper.STRAPI_API_URL + '/gift-cards/' + id, { params: payload }, HttpHelper.getAuthHeader());
    }

    send(payload) {
        return axios.post(HttpHelper.BACKEND_API_URL + '/giftcards/sendemail', payload, HttpHelper.getHeader());
    }

}

export default new GiftCardService();