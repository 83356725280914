class StorageHelper {
    setToken(accessToken) {
        localStorage.setItem("token", accessToken);
    }

    getToken() {
        return localStorage.getItem('token');
    }

    setUser(data) {
        localStorage.setItem("user", JSON.stringify(data));
    }

    getUser() {
        return JSON.parse(localStorage.getItem("user"));
    }

    setCart(data) {
        localStorage.setItem("cart", JSON.stringify(data));
    }

    getCart() {
        return JSON.parse(localStorage.getItem("cart"));
    }

    setGiftcards(data) {
        localStorage.setItem("giftcards", JSON.stringify(data));
    }

    getGiftcards() {
        return JSON.parse(localStorage.getItem("giftcards"));
    }

}

export default new StorageHelper();