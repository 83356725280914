import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class PickProductService {

    getAll(payload) {
        return axios.get(HttpHelper.STRAPI_API_URL + '/our-top-picks', { params: payload }, HttpHelper.getHeader());
    }

    getOne(id, payload) {
        return axios.get(HttpHelper.STRAPI_API_URL + '/our-top-picks/' + id, { params: payload }, HttpHelper.getHeader());
    }

}

export default new PickProductService();