import React from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Link } from "react-router-dom";

function Reservations() {
    return (
        <>
            <Header />
            <section className="reservations-banner-wrapper">
                <img src="./images/reservations-banner-img1.jpg" />
            </section>
            <section className="reservations-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="yellow-chilli-top-menu">
                                <ul>
                                    <li><button type="button">Overview</button></li>
                                    <li><button type="button">Photos</button></li>
                                    <li><button type="button">Menu</button></li>
                                    <li><button type="button">Reviews</button></li>
                                </ul>
                            </div>
                            <div className="reservations-left-wrap">
                                <div className="overview-wrap">
                                    <h3>The yellow chilli tustin</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                                    <div className="review-wrap">
                                        <ul>
                                            <li>
                                                <img src="./images/testimonials-rating-img.png" /> 5
                                            </li>
                                            <li><i className="las la-comment-alt"></i> 161 Reviews</li>
                                            <li><i className="las la-money-bill"></i> $30 and under</li>
                                            <li><i className="las la-utensils"></i> Indian</li>
                                        </ul>
                                    </div>
                                    <div className="top-tab-wrap">
                                        <span>Top Tags:</span>
                                        <ul>
                                            <li>
                                                <Link> Creative Cuisine </Link>
                                            </li>
                                            <li>
                                                <Link> Fit for Foodies </Link>
                                            </li>
                                            <li>
                                                <Link> Great for Outdoor Dining </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="photos-wrapper">
                                    <h3>Photos </h3>
                                    <div className="inner-photo-wrap">
                                        <ul className="row">
                                            <li className="col-12 col-md-6 col-lg-4 mb-4">
                                                <div className="photos-wrap">
                                                    <img className="img-fluid" src="./images/gallery-img1.jpg" />
                                                </div>
                                            </li>
                                            <li className="col-12 col-md-6 col-lg-4 mb-4">
                                                <div className="photos-wrap">
                                                    <img className="img-fluid" src="./images/gallery-img2.jpg" />
                                                </div>
                                            </li>
                                            <li className="col-12 col-md-6 col-lg-4 mb-4">
                                                <div className="photos-wrap">
                                                    <img className="img-fluid" src="./images/gallery-img3.jpg" />
                                                </div>
                                            </li>
                                            <li className="col-12 col-md-6 col-lg-4 mb-4">
                                                <div className="photos-wrap">
                                                    <img className="img-fluid" src="./images/gallery-img4.jpg" />
                                                </div>
                                            </li>
                                            <li className="col-12 col-md-6 col-lg-4 mb-4">
                                                <div className="photos-wrap">
                                                    <img className="img-fluid" src="./images/gallery-img1.jpg" />
                                                </div>
                                            </li>
                                            <li className="col-12 col-md-6 col-lg-4 mb-4">
                                                <div className="photos-wrap">
                                                    <img className="img-fluid" src="./images/gallery-img2.jpg" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="reserv-menu-wrap">
                                    <h3>Menu</h3>
                                    <div className="reserv-menu-inner">
                                        <article className="reserv-menu-list">
                                            <h4>Soup</h4>
                                            <ul>
                                                <li>
                                                    <h4>
                                                        <span>Tomato Basil Shorba</span>
                                                        <span>$5.99</span>
                                                    </h4>
                                                    <p>Indian tomato soup with a western twist</p>
                                                </li>
                                                <li>
                                                    <h4>
                                                        <span>Tomato Basil Shorba</span>
                                                        <span>$5.99</span>
                                                    </h4>
                                                    <p>Indian tomato soup with a western twist</p>
                                                </li>
                                                <li>
                                                    <h4>
                                                        <span>Tomato Basil Shorba</span>
                                                        <span>$5.99</span>
                                                    </h4>
                                                    <p>Indian tomato soup with a western twist</p>
                                                </li>
                                            </ul>
                                        </article>
                                        <article className="reserv-menu-list">
                                            <h4>Salads</h4>
                                            <ul>
                                                <li>
                                                    <h4>
                                                        <span>Papaya Peanut Kachumber</span>
                                                        <span>$9.99</span>
                                                    </h4>
                                                    <p>Raw papaya and roasted peanuts with honey-lemon dressing</p>
                                                </li>
                                                <li>
                                                    <h4>
                                                        <span>Bowl of Greens</span>
                                                        <span>$7.99</span>
                                                    </h4>
                                                    <p>Fresh vegetables and lettuce in tamarind vinaigrette</p>
                                                </li>
                                                <li>
                                                    <h4>
                                                        <span>Paneer / Chicken Tikka and Avocado Salad</span>
                                                        <span>$5.99</span>
                                                    </h4>
                                                    <p>Indian tomato soup with a western twist</p>
                                                </li>
                                                <li>
                                                    <h4>
                                                        <span>Leafy Salad</span>
                                                        <span>$7.99</span>
                                                    </h4>
                                                    <p>Fresh greens tossed with nuts, sprouts, carrot, beetroot and cranberries</p>
                                                </li>
                                            </ul>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="reservations-sidebar">
                                <div className="make-reservation-wrap">
                                    <h3>Make a reservation</h3>
                                    <div className="make-reservation-inner">

                                        <div className="row">
                                            <div className="col-12 col-md-12 mb-3">
                                                <div className="form-group">
                                                    <label>Party Size</label>
                                                    <select className="form-select">
                                                        <option>1 person</option>
                                                        <option>2 people</option>
                                                        <option>3 people</option>
                                                        <option>4 people</option>
                                                        <option>5 people</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label>Date</label>
                                                    <input type="date" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label>Time</label>
                                                    <input type="time" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12">
                                                <div className="form-group">
                                                    <button className="btn btn-warning" type="submit">Find a time</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Reservations;