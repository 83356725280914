import StorageHelper from "./StorageHelper";

class HttpHelper {
    STRAPI_URL = process.env.REACT_APP_STRAPI_URL;
    STRAPI_API_URL = process.env.REACT_APP_STRAPI_API_URL;
    BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
    WEB_URL = process.env.REACT_APP_WEB_URL;

    getHeader() {
        return {
            headers: {
                "Content-Type": "application/json",
            }
        }
    }

    getAuthHeader() {
        return {
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + StorageHelper.getToken()
            }
        }
    }

    getAuthHeaderMultiPart() {
        return {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": 'Bearer ' + StorageHelper.getToken()
            }
        }
    }

    isLogin() {
        return (StorageHelper.getToken()) ? true : false;
    }

    Logout() {
        localStorage.clear();
        window.location.replace('/')
    }

}

export default new HttpHelper();