
import React, { useState, useEffect } from 'react';
import { useStripe } from "@stripe/react-stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";

import GiftCardService from "../../../services/GiftCardService";
import StorageHelper from '../../../helper/StorageHelper';

const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_PK);

const MessageComponent = () => {
    const stripe = useStripe();
    const [seconds, setSeconds] = useState(5);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [paymentStatusClass, setPaymentStatusClass] = useState('text-secondary text-center mb-4');
    const [message, setMessage] = useState("Verify your payment info. Please wait...");


    async function sendEmail(query) {
        const giftcards = StorageHelper.getGiftcards();
        const response = await GiftCardService.update(giftcards.id, { data: query });
        if (response.status === 200) {
            if (query.payment_status === 'succeeded' || query.payment_status === 'processing') {
                const res = await GiftCardService.send(giftcards.attributes);
                if (res.status === 200) {
                    setTimeout(() => {
                        window.location.replace("/");
                    }, ((seconds) * 1000));
                    const interval = setInterval(() => {
                        setSeconds(seconds => seconds - 1);
                    }, 1000);
                    return () => clearInterval(interval);
                }
            }
        }
    }

    useEffect(() => {
        if (!stripe) {
            return;
        }

        // Retrieve the "payment_intent_client_secret" query parameter appended to
        // your return_url by Stripe.js
        const clientSecret = new URLSearchParams(window.location.search).get(
            'payment_intent_client_secret'
        );
        const payment_intent = new URLSearchParams(window.location.search).get(
            'payment_intent'
        );

        // Retrieve the PaymentIntent
        stripe
            .retrievePaymentIntent(clientSecret)
            .then(({ paymentIntent }) => {
                // Inspect the PaymentIntent `status` to indicate the status of the payment
                // to your customer.
                //
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                //
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification

                setPaymentStatus(paymentIntent.status);
                switch (paymentIntent.status) {
                    case 'succeeded':
                        setMessage('Success! Payment received. Your Gift card has been sent');
                        setPaymentStatusClass('text-success text-center mb-4');
                        break;
                    case 'processing':
                        setMessage("Payment processing. We'll update you when payment is received. Your Gift card has been sent");
                        setPaymentStatusClass('text-success text-center mb-4');
                        break;
                    case 'requires_payment_method':
                        setMessage('Payment failed. Please try another payment method.');
                        setPaymentStatusClass('text-danger text-center mb-4');
                        break;
                    default:
                        setMessage('Something went wrong.');
                        setPaymentStatusClass('text-info text-center mb-4');
                        break;
                }
                try {
                    sendEmail({
                        payment_id: payment_intent,
                        payment_status: paymentIntent.status,
                    });
                } catch (error) {
                    console.log(error);
                }
            });
    }, [stripe]);

    return (
        <section className="food-menu-wrapper">
            <div className='container'>
                <div className="row">
                    <div className="col-12 mb-4">
                        <h3 className={paymentStatusClass}>{message}</h3>
                        {paymentStatus && <p className="text-secondary text-center">Automatically redirect to home page after {seconds} seconds</p>}
                    </div>
                </div>
            </div>
        </section>
    );
};

function PaymentStatus() {

    // const [seconds, setSeconds] = useState(5);
    // const [message, setMessage] = useState("Verify your payment info. Please wait...");

    // // update giftcards payment status
    // let query = {
    //     payment_id: payment_intent,
    //     payment_status: paymentIntent.status,
    // };
    // const response = await GiftCardService.update(giftcards.id, { data: query });

    // console.log(response);

    // if (paymentIntent.status === 'succeeded' || paymentIntent.status === 'processing') {
    //     // const res = GiftCardService.send(giftcards.attributes);

    //     // console.log(res);

    //     // setTimeout(() => {
    //     //     window.location.replace("/");
    //     // }, (seconds * 1000));

    //     // const interval = setInterval(() => {
    //     //     setSeconds(seconds => seconds - 1);
    //     // }, 1000);
    //     // return () => clearInterval(interval);
    // }

    // useEffect(() => {

    // }, [stripe]);


    return (
        <Elements stripe={stripePromise}>
            <>
                <Header />
                <MessageComponent />
                <Footer />
            </>
        </Elements>
    );
}

export default PaymentStatus;