import React, { useContext, useEffect, useState } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { Link } from "react-router-dom";

import { CartContext } from "../../../provider/CartContext";


function FoodMenu() {


    const { cartItems, getTotalCartAmount, removeFromCart, clearCartItem } = useContext(CartContext);
    let [checkout, setCheckout] = useState(false);

    let carts = cartItems;

    // useEffect(() => {
    //     if (!cartItems.length) {
    //         window.location.replace('/cart');
    //     }
    // }, [cartItems]);

    const onCheckout = () => {
        console.log('api call then');

        setCheckout(true);
        clearCartItem();

    }

    return (
        <>
            <Header />
            <section className="food-menu-wrapper">
                <div className='container'>
                    {checkout &&
                        <div className="row">
                            <div className="col-12">
                                <h3 className="text-success">Order placed successfully</h3>
                            </div>
                        </div>
                    }

                    {!checkout &&
                        <div className="row">
                            <div className="col-12 col-md-8 mb-4">
                                <div className="food-left-wrapper">
                                    <div className="heading">
                                        {/* <div className="left-logo">
                                        <img className="img-fluid" src="./images/logo.png" />
                                    </div> */}
                                        <h3>The Yellow Chilli - Tustin 2463 Park Avenue</h3>
                                        <p>2463 Park Avenue, Tustin, CA</p>
                                        <h5><span>Scheduled Orders Only</span></h5>
                                        <h6>Next Available: 11/9 at 11:45 AM PST <Link>More Info</Link></h6>
                                    </div>
                                    <hr />
                                    <div className="food-menu-wrap">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 mb-4">
                                                <select className="form-select">
                                                    <option>Food Menu</option>
                                                    <option>Beverage Menu</option>
                                                    <option>Reservation</option>
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-6 mb-4">
                                                <div className="restaurantHeader-module">
                                                    <ul>
                                                        <li>
                                                            <Link to="#Specials">SPECIALS</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#Soups">SOUPS</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#Salads">SALADS</Link>
                                                        </li>
                                                        <li>
                                                            <Link>PAPAD and RAITA</Link>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="restaurant-menu-wrap">
                                                <h3 id="Specials">SPECIALS </h3>
                                                <ul className="row">
                                                    <li className="col-12 col-md-6 mb-4">
                                                        <div className="menu-item-content">
                                                            <div className="left-content">
                                                                <h4>FAMILY MEAL</h4>
                                                                <p>Each Meal includes one Appetizer and two Mains or one main & a bowl of soup of your choice. Comes with complimentary rice pilaf</p>
                                                            </div>
                                                            <div className="price">
                                                                <h5>$42.99</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <h3 id="Soups">SOUPS </h3>
                                                <ul className="row">
                                                    <li className="col-12 col-md-6 mb-4">
                                                        <div className="menu-item-content">
                                                            <div className="left-content">
                                                                <h4>Tomato Basil Shorba</h4>
                                                                <p>Indian tomato soup with a western twist</p>
                                                            </div>
                                                            <div className="price">
                                                                <h5>$6.99</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="col-12 col-md-6 mb-4">
                                                        <div className="menu-item-content">
                                                            <div className="left-content">
                                                                <h4>Coconut Zaffrani Shorba</h4>
                                                                <p>Indian tomato soup with a western twist</p>
                                                            </div>
                                                            <div className="price">
                                                                <h5>$6.99</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="col-12 col-md-6 mb-4">
                                                        <div className="menu-item-content">
                                                            <div className="left-content">
                                                                <h4>Desi Manchow Soup</h4>
                                                                <p>Indian tomato soup with a western twist</p>
                                                            </div>
                                                            <div className="price">
                                                                <h5>$6.99</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <h3 id="Salads">SALADS </h3>
                                                <ul className="row">
                                                    <li className="col-12 col-md-6 mb-4">
                                                        <div className="menu-item-content">
                                                            <div className="left-content">
                                                                <h4>Papaya Peanut Kachumber</h4>
                                                                <p>Raw papaya and roasted peanuts with honey-lemon dressing</p>
                                                            </div>
                                                            <div className="price">
                                                                <h5>$6.99</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="col-12 col-md-6 mb-4">
                                                        <div className="menu-item-content">
                                                            <div className="left-content">
                                                                <h4>Paneer / Chicken Tikka and Avocado Salad</h4>
                                                                <p>Spicy cottage cheese / chicken tikka and creamy avocado in vinaigrette</p>
                                                            </div>
                                                            <div className="price">
                                                                <h5>$6.99</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="col-12 col-md-6 mb-4">
                                                        <div className="menu-item-content">
                                                            <div className="left-content">
                                                                <h4>Bowl of Greens</h4>
                                                                <p>Fresh vegetables and lettuce in tamarind vinaigrette</p>
                                                            </div>
                                                            <div className="price">
                                                                <h5>$6.99</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="foodmenu-sidebarcart">
                                    <div className="foodmenu-cart-inner mb-4">
                                        <h3>Your Order</h3>
                                        <ul className="sidebarcart-list">
                                            {carts.map((row, id) => {
                                                console.log(row)
                                                return (<li key={id}>
                                                    <div className="cart-product-list">
                                                        <div className="product-title">
                                                            <div className="count-item">
                                                                <span>1</span>
                                                            </div>
                                                            <div className="product-name">
                                                                <h4>{row.attributes.name}</h4>
                                                                {/* <button>Edit</button> */}
                                                                <button onClick={() => removeFromCart(row.id)}>Remove</button>
                                                            </div>
                                                        </div>
                                                        <div className="product-price">
                                                            <h5>${row.subtotal}</h5>
                                                        </div>

                                                    </div>
                                                </li>)
                                            })}

                                            {/* <li>
                                            <div className="cart-product-list">
                                                <div className="product-title">
                                                    <div className="count-item">
                                                        <span>1</span>
                                                    </div>
                                                    <div className="product-name">
                                                        <h4>Coconut Zaffrani Shorba</h4>
                                                        <button>Edit</button>
                                                        <button>Remove</button>
                                                    </div>
                                                </div>
                                                <div className="product-price">
                                                    <h5>$10.99</h5>
                                                </div>

                                            </div>
                                        </li>
                                        
                                        <li>
                                            <div className="cart-product-list">
                                                <div className="product-title">
                                                    <div className="count-item">
                                                        <span>1</span>
                                                    </div>
                                                    <div className="product-name">
                                                        <h4>Desi Manchow Soup</h4>
                                                        <button>Edit</button>
                                                        <button>Remove</button>
                                                    </div>
                                                </div>
                                                <div className="product-price">
                                                    <h5>$10.99</h5>
                                                </div>

                                            </div>
                                        </li> */}

                                        </ul>
                                        <div className="total">
                                            <div className="name">
                                                <h5>Total</h5>
                                            </div>
                                            <div className="price">
                                                <h4>{getTotalCartAmount()}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="checkout-btn">
                                        <button onClick={onCheckout} className="btn btn-warning"> Proceed to checkout </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section>
            <Footer />
        </>
    )
}

export default FoodMenu;