import React, { } from 'react';
import { Link } from 'react-router-dom';

function Categories(props) {

    let Category = props.data;
    
    return (
        <>
            <div className='shop-sidebar'>
                <h3>Search By</h3>
                <ul>
                    <li className='active'>
                        <Link to='/shop'>Popular Items</Link>
                    </li>
                    {Category && Category.map((row, i) => {
                        let shopurl = '/shop/' + row.attributes.slug;
                        return (<li key={i}><Link to={shopurl}>{row.attributes.name}</Link></li>)
                    })}
                    
                </ul>
            </div>
        </>
    )
}

export default Categories;