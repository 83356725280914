import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class AuthService {

    Login(payload) {
        return axios.post(HttpHelper.STRAPI_API_URL + '/auth/local', payload, HttpHelper.getHeader());
    }

    Register(payload) {
        return axios.post(HttpHelper.STRAPI_API_URL + '/auth/local/register', payload, HttpHelper.getHeader());
    }

    ForgotPassword(payload) {
        return axios.post(HttpHelper.STRAPI_API_URL + '/auth/forgot-password', payload, HttpHelper.getHeader());
    }

    ResetPassword(payload) {
        return axios.post(HttpHelper.STRAPI_API_URL + '/auth/reset-password', payload, HttpHelper.getHeader());
    }

}

export default new AuthService();