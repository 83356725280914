import React, { useState, useEffect, useContext } from "react";
import validator from 'validator';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useFormik } from "formik";
import { Toast } from '../../Layout/Alert';

import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import CheckoutForm from './CheckoutForm';

import { UserContext } from "../../../provider/UserContext";
import PaymentsService from "../../../services/PaymentsService";
import GiftCardService from "../../../services/GiftCardService";
import StorageHelper from '../../../helper/StorageHelper';
import { GiftCardsForm } from "../../../helper/ValidationHelper";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_PK);

function GiftCards() {

    const { user } = useContext(UserContext);

    const [clientSecret, setClientSecret] = useState("");

    const [fromEmail, setFromEmail] = useState('');
    const [userid, setUserid] = useState('');

    useEffect(() => {
        if (user) {
            setFromEmail(user.email);
            setUserid(user.id);
        }
    }, [user]);

    // const [amount, setamount] = useState(0);
    // const [message, setmessage] = useState('');
    // const [toEmail, settoEmail] = useState('');
    // const [delivaryDate, setdelivaryDate] = useState('');

    // const [error, setError] = useState(false);
    // const [amountError, setAmountError] = useState(false);
    // const [sendEmailError, setSendEmailError] = useState(false);
    // const [delivaryDateError, setDelivaryDateError] = useState(false);

    // function handleChange(event) {
    //     const name = event.target.name;
    //     const value = event.target.value;
    //     if (name === 'amount') {
    //         setamount(value);
    //     }
    //     else if (name === 'message') {
    //         setmessage(value);
    //     }
    //     else if (name === 'toEmail') {
    //         settoEmail(value);
    //     }
    //     else if (name === 'delivaryDate') {
    //         setdelivaryDate(value);
    //     }

    //     FormValidation();
    // }

    // function FormValidation() {
    //     var error = false;
    //     if (amount <= 500 && amount >= 5) {
    //         setAmountError(false);
    //     } else {
    //         setAmountError(true);
    //         error = true;
    //     }
    //     if (validator.isEmail(toEmail)) {
    //         setSendEmailError(false);
    //     } else {
    //         setSendEmailError(true);
    //         error = true;
    //     }
    //     if (delivaryDate) {
    //         setDelivaryDateError(false);
    //     } else {
    //         setDelivaryDateError(true);
    //         error = true;
    //     }
    //     setError(error);
    //     return error;
    // }

    // async function createPaymentIntent() {
    //     if (FormValidation()) {
    //         return false;
    //     }
    //     let query = {
    //         amount: amount,
    //         message: message,
    //         to_email: toEmail,
    //         from_email: fromEmail,
    //         delivary_date: delivaryDate,
    //         payment_status: "processing",
    //         is_used: false,
    //         users: userid,
    //     };

    //     let response = await PaymentsService.createPaymentIntent(query);
    //     if (response.status === 200) {
    //         query.clientSecret = response.data.clientSecret;
    //         response = await GiftCardService.create({ data: query });
    //         if (response.status === 200) {
    //             setClientSecret(query.clientSecret);
    //             StorageHelper.setGiftcards(response.data.data);
    //         }
    //     }

    // }

    const [isLoading, setIsLoading] = useState(false);

    const submitHandler = async (formvalues) => {
        try {
            setIsLoading(true);

            let query = {
                amount: formvalues.amount,
                message: formvalues.message,
                to_email: formvalues.to_email,
                from_email: fromEmail,
                delivary_date: formvalues.delivary_date,
                payment_status: "processing",
                is_used: false,
                users: userid,
            };

            let response = await PaymentsService.createPaymentIntent(query);
            if (response.status === 200) {
                query.clientSecret = response.data.clientSecret;
                response = await GiftCardService.create({ data: query });
                if (response.status === 200) {
                    setClientSecret(query.clientSecret);
                    StorageHelper.setGiftcards(response.data.data);
                }
            } else {
                setIsLoading(false);
                Toast.fire({
                    icon: 'warning',
                    title: "Something went wrong. try again later"
                });
            }
        } catch (err) {
            setIsLoading(false);
            Toast.fire({
                icon: 'error',
                title: 'Something went wrong. try again later'
            })
        }
    };

    const initialValues = {
        amount: 5,
        message: "",
        to_email: "",
        delivary_date: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: GiftCardsForm,
        onSubmit: (values, action) => {
            submitHandler(values);
            // action.resetForm()
        },
    });

    const appearance = {
        theme: 'flat',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <>
            <Header />
            <section className="giftcard-wrapper">
                <div className="container-sm">
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-2 mb-4 mb-md-0">
                            {!clientSecret &&
                                <div className="giftcard-inner-wrap">
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="title mb-3">
                                            <h3>Give a Gift</h3>
                                        </div>

                                        <div className="form-title">
                                            <h4>Choose Amount</h4>
                                        </div>
                                        <div className="form-group mb-4">
                                            <div className="input-group align-items-centers">
                                                <span className="input-group-text" id="basic-addon1">$</span>
                                                <input
                                                    type="number"
                                                    placeholder=""
                                                    name="amount"
                                                    autoComplete="off"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.amount}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="text-secondary small">Note : Amount between $5.00 to $500.00.</div>
                                            {formik.touched.amount && formik.errors.amount ? (
                                                <p className="text-danger small "><strong>{formik.errors.amount}</strong></p>
                                            ) : null}
                                        </div>
                                        <div className="form-title">
                                            <h4>Personalize</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-12 mb-3">
                                                <div className="form-group">
                                                    <label>From</label>
                                                    <input
                                                        type="email"
                                                        placeholder=""
                                                        name="from_email"
                                                        autoComplete="off"
                                                        value={fromEmail}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 mb-3">
                                                <div className="form-group">
                                                    <label>Message</label>
                                                    <textarea
                                                        rows="3"
                                                        placeholder=""
                                                        name="message"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.message}
                                                        className="form-control"
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-title">
                                            <h4>Schedule Delivery</h4>
                                        </div>
                                        <label className="mb-3">Send To *</label>
                                        {/* <ul className="nav nav-tabs mb-3 comon-tab" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="email-tab" data-bs-toggle="tab" data-bs-target="#email" type="button" role="tab" aria-controls="email" aria-selected="true">Email</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="phone-tab" data-bs-toggle="tab" data-bs-target="#phone" type="button" role="tab" aria-controls="phone" aria-selected="false">Phone</button>
                                            </li>
                                        </ul> */}
                                        <div className="tab-content mb-3" id="myTabContent">
                                            <div className="tab-pane fade show active" id="email" role="tabpanel" aria-labelledby="email-tab">
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        placeholder="Recipient Email"
                                                        name="to_email"
                                                        autoComplete="off"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.to_email}
                                                        className="form-control"
                                                    />
                                                </div>
                                                {formik.touched.to_email && formik.errors.to_email ? (
                                                    <p className="text-danger small "><strong>{formik.errors.to_email}</strong></p>
                                                ) : null}
                                            </div>
                                            {/* <div className="tab-pane fade" id="phone" role="tabpanel" aria-labelledby="phone-tab">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Recipient Phone" />
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label for="birthday">Delivery Date *</label>
                                            <input
                                                type="date"
                                                placeholder="Recipient Email"
                                                name="delivary_date"
                                                autoComplete="off"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.delivary_date}
                                                className="form-control"
                                            />
                                            {formik.touched.delivary_date && formik.errors.delivary_date ? (
                                                <p className="text-danger small "><strong>{formik.errors.delivary_date}</strong></p>
                                            ) : null}
                                        </div>
                                        <div className="form-title mt-3">
                                            <h4>Preview</h4>
                                        </div>
                                        <div className="giftcard-preview-wrap">
                                            <div className="giftcard-preview">
                                                <div className="logo">
                                                    <img className="img-fluid" src="./images/logo.png" />
                                                </div>
                                                <h4>Card Balance</h4>
                                                <h3>${formik.values.amount}</h3>
                                            </div>
                                        </div>
                                        <div className="giftcard-btn-wrap">
                                            <ul>
                                                {/* <li>
                                                    <Link className="btn btn-outline-warning"> Add Another eGift Card </Link>
                                                </li> */}
                                                <li>
                                                    {/* <input type="button" className="btn btn-warning" value="Check Out" onClick={createPaymentIntent} /> */}
                                                    <button disabled={isLoading} type='submit' className="btn btn-warning">
                                                        {isLoading ?
                                                            <div className="spinner-border" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div> : "Check Out"}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </form>
                                </div>
                            }
                            {clientSecret &&
                                <Elements stripe={stripePromise} options={options}>
                                    <CheckoutForm />
                                </Elements>
                            }
                        </div>
                        {/* <div className="col-12 col-md-4">
                            <div className="gift-right-sidebar">
                                <div className="order-online-wrap mb-4">
                                    <h3>We’re open for online orders.</h3>
                                    <p>Stay safe, support local, and still get your favorites.</p>
                                    <Link className="btn btn-warning">Order Online</Link>
                                </div>
                                <div className="order-online-wrap">
                                    <h4>Your Order</h4>
                                    <p>You have no items in your cart. </p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default GiftCards;