import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class ProductService {

    getAll(payload) {
        return axios.get(HttpHelper.STRAPI_API_URL + '/products', { params: payload }, HttpHelper.getHeader());
    }

    getOne(id, payload) {
        return axios.get(HttpHelper.STRAPI_API_URL + '/products/' + id, { params: payload }, HttpHelper.getHeader());
    }

}

export default new ProductService();