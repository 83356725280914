import React, { useContext, useEffect, useState } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { Link } from "react-router-dom";

import { CartContext } from "../../../provider/CartContext";


function Order() {


    const { cartItems, getTotalCartAmount, clearCartItem } = useContext(CartContext);
    let [carts, setCarts] = useState(cartItems);
    let [total, setTotal] = useState(getTotalCartAmount());

    useEffect(() => {
        if (cartItems.length)
            clearCartItem();
        else
            window.location.replace('/')
    }, []);

    return (
        <>
            <Header />
            <section className="food-menu-wrapper">
                <div className='container'>

                    <div className="row">
                        <div className="col-12">
                            <h3 className="text-success">Order placed successfully</h3>
                        </div>
                    </div>


                    {carts &&
                        <div className="row">

                            <div className="col-12 col-md-12">
                                <div className="foodmenu-sidebarcart">
                                    <div className="foodmenu-cart-inner mb-12">
                                        <h3>Your Order</h3>
                                        <ul className="sidebarcart-list">
                                            {carts.map((row, id) => {
                                                console.log(row)
                                                return (<li key={id}>
                                                    <div className="cart-product-list">
                                                        <div className="product-title">
                                                            <div className="count-item">
                                                                <span>1</span>
                                                            </div>
                                                            <div className="product-name">
                                                                <h4>{row.attributes.name}</h4>
                                                                {/* <button>Edit</button> */}
                                                                {/* <button onClick={() => removeFromCart(row.id)}>Remove</button> */}
                                                            </div>
                                                        </div>
                                                        <div className="product-price">
                                                            <h5>${row.subtotal}</h5>
                                                        </div>

                                                    </div>
                                                </li>)
                                            })}

                                        </ul>
                                        <div className="total">
                                            <div className="name">
                                                <h5>Total</h5>
                                            </div>
                                            <div className="price">
                                                <h4>{total}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="checkout-btn">
                                        <button onClick={onCheckout} className="btn btn-warning"> Proceed to checkout </button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Order;