import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import HttpHelper from "../../helper/HttpHelper";

function SliderProducts(props) {

    const url = HttpHelper.STRAPI_URL;
    
    const products = props.products;

    function RelatedProductNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
            ><span className='fa fa-angle-right' aria-hidden='true'></span></div>
        );
    }

    function RelatedProductPrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
            >
                <span className='fa fa-angle-left' aria-hidden='true'></span>
            </div>
        );
    }

    // related product js
    const RelatedProductSlider = {
        dots: false,
        arrows: true,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <RelatedProductPrevArrow />,
        nextArrow: <RelatedProductNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }

        ]
    };

    return (

        <section className='related-product-wrapper'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 mb-4'>
                        <div className='comon-title'>
                            <h3>{props.title}</h3>
                        </div>
                    </div>
                    <div className='col-12'>
                        <Slider {...RelatedProductSlider}>
                            {products && products.map((rows) => {
                                var row = rows;
                                if('product' in rows.attributes){
                                    row = rows.attributes.product.data;
                                }
                                let prodetailsurl = "/products/" + row.id;
                                return (
                                    // <Link to={prodetailsurl}>
                                        <div className="related-prd-sld-items" key={row.id}>
                                            <div className="product-wrap">
                                                <div className="image-box">
                                                    {row.attributes.images && <img alt={row.attributes.name} src={url + row.attributes.images?.data[0]?.attributes.formats.thumbnail.url} />}
                                                </div>
                                                <div className="prd-content">
                                                    <div className="rating-img">
                                                        <img className="img-fluid" src="/images/rating-img1.png" alt="" />
                                                    </div>
                                                    <h3>{row.attributes.name}</h3>
                                                    <h4>$ {row.attributes.actual_price}</h4>
                                                    <Link className="btn btn-warning" to={prodetailsurl}>Order Now</Link>
                                                </div>
                                            </div>
                                        </div>
                                    // </Link>
                                )
                            })}
                            {/* <div className="related-prd-sld-items">
                                <div className="product-wrap">
                                    <div className="image-box">
                                        <img className="img-fluid" src="/images/top-picks-product-img1.jpg" alt="" />
                                    </div>
                                    <div className="prd-content">
                                        <div className="rating-img">
                                            <img className="img-fluid" src="/images/rating-img1.png" alt="" />
                                        </div>
                                        <h3>Chanajor Garam Tikki</h3>
                                        <h4>$17.99</h4>
                                        <Link className="btn btn-warning" to='/'>Order Now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="related-prd-sld-items">
                                <div className="product-wrap">
                                    <div className="image-box">
                                        <img className="img-fluid" src="/images/top-picks-product-img2.jpg" alt="" />
                                    </div>
                                    <div className="prd-content">
                                        <div className="rating-img">
                                            <img className="img-fluid" src="/images/rating-img1.png" alt="" />
                                        </div>
                                        <h3>Chanajor Garam Tikki</h3>
                                        <h4>$17.99</h4>
                                        <Link className="btn btn-warning" to='/'>Order Now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="related-prd-sld-items">
                                <div className="product-wrap">
                                    <div className="image-box">
                                        <img className="img-fluid" src="/images/top-picks-product-img3.jpg" alt="" />
                                    </div>
                                    <div className="prd-content">
                                        <div className="rating-img">
                                            <img className="img-fluid" src="/images/rating-img1.png" alt="" />
                                        </div>
                                        <h3>Chanajor Garam Tikki</h3>
                                        <h4>$17.99</h4>
                                        <Link className="btn btn-warning" to='/'>Order Now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="related-prd-sld-items">
                                <div className="product-wrap">
                                    <div className="image-box">
                                        <img className="img-fluid" src="/images/top-picks-product-img1.jpg" alt="" />
                                    </div>
                                    <div className="prd-content">
                                        <div className="rating-img">
                                            <img className="img-fluid" src="/images/rating-img1.png" alt="" />
                                        </div>
                                        <h3>Chanajor Garam Tikki</h3>
                                        <h4>$17.99</h4>
                                        <Link className="btn btn-warning" to='/'>Order Now</Link>
                                    </div>
                                </div>
                            </div> */}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default SliderProducts;